import mixpanel from "mixpanel-browser";

/**
 * TODO: There is no need for this pseudo-singleton init pattern - this
 * has been copied from sifted-shared-utils, and could be refactored
 * to interface with the mixpanel-browser library directly
 */
export class Mixpanel {
  constructor(token: string) {
    if (typeof window !== "undefined") {
      mixpanel.init(token, {
        debug: false,
        opt_out_tracking_by_default: true,
        ignore_dnt: true,
      });
    }
  }

  track(...args: [string, object]) {
    if (typeof window !== "undefined") {
      mixpanel.track(...args);
    }
  }

  identify(userId: string) {
    if (typeof window !== "undefined") {
      mixpanel.identify(userId);
    }
  }

  peopleSet(data: object) {
    if (typeof window !== "undefined") {
      mixpanel.people.set(data);
    }
  }

  reset() {
    if (typeof window !== "undefined") {
      mixpanel.reset();
    }
  }

  opt_out_tracking() {
    if (typeof window !== "undefined") {
      mixpanel.opt_out_tracking();
    }
  }

  opt_in_tracking() {
    if (typeof window !== "undefined") {
      mixpanel.opt_in_tracking();
    }
  }

  has_opted_in_tracking() {
    if (typeof window !== "undefined") {
      return mixpanel.has_opted_in_tracking();
    }
  }

  has_opted_out_tracking() {
    if (typeof window !== "undefined") {
      return mixpanel.has_opted_out_tracking();
    }
  }
}

export const setMixpanelOnPageLoad = () => {
  if (!mixpanel) return;
  // Collecting this data to debug login/logout issues
  mixpanel.register({
    isLinkedIn: /linkedin/i.test(navigator.userAgent),
    userAgent: navigator.userAgent,
  });
  if (
    window.Cookiebot?.consent?.statistics &&
    !mixpanel.has_opted_in_tracking()
  ) {
    mixpanel.opt_in_tracking();
  }
};

export const setMixpanelOnAccept = () => {
  if (!mixpanel) return;
  if (
    window.Cookiebot?.consent?.statistics &&
    !mixpanel.has_opted_in_tracking()
  ) {
    mixpanel.opt_in_tracking();
  } else if (!mixpanel.has_opted_out_tracking) {
    mixpanel.opt_out_tracking();
  }
};

export const setMixpanelOnDecline = () => {
  if (!mixpanel) return;
  if (
    !window.Cookiebot?.consent?.statistics &&
    !mixpanel.has_opted_out_tracking()
  ) {
    mixpanel.opt_out_tracking();
  }
};
