import axios from "axios";
import { datadogLogger } from "lib/datadog/logger";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const postRequest = (url: string, data: object) =>
  axios
    .post(url, { ...data })
    .then((res) => res.data)
    .catch((err) => datadogLogger.error(err));

export default fetcher;
